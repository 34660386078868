html {
  font-size: 14px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

//page
.page-header {
  font-size: 36px;
  font-weight: bold;
}

.page-control {
  margin: 32px 0;

  &__button {
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    padding: 10px 16px;
  }
}

.page-datatable {
  .p-datatable-thead th {
    font-size: 14px;
    padding: 8px;
    font-weight: 600;
  }

  .p-datatable-tbody td {
    font-size: 14px;
    padding: 8px;
  }
}

.page-subheader {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 18px;
}
.page {
  &__details {
    display: flex;
    flex-flow: column wrap;
    gap: 16px;
  }
  &__details-panel{
    & .p-panel-title {
      padding: 12px;
      font-size: 14px;
      font-weight: 600;
    }
  }
  &__info {
    font-size: 14px;
    padding: 12px;
    display: flex;
    flex-flow: column wrap;
    gap: 12px;
    & div {
      font-weight: 600;
      span {
        font-weight: 400;
      }
    }
  }
  &__panel {
    max-width: 862px;
    margin-bottom: 2rem;
    .p-panel-content {
      border-radius: 1rem;
      padding: 2rem;
    }
  }
}
// page
//header
.header {
  margin: 2rem 0;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  &__title {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1rem;
    &>span {
      font-size: 36px;
      font-weight: bold;
    }
  }
  &__controls {
    display: flex;
    flex-flow: row nowrap;
    gap: 1rem;
  }
}
//header
//layout
.layout {
  display: flex;
  flex-flow: row nowrap;
  column-gap: 34px;

  &__left-menu {
    flex: 0 1 84px;
  }

  &__container {
    height: 100vh;
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }

  &__content {padding: 0 34px 34px 0;}

  &__footer {
    text-align: right;
    padding: 0 8px 8px;

    .ver {
      font-size: 12px;
    }
  }
}

//layout

//button
.control-buttons {
  margin: 2.3rem 0;
  display: flex;
  flex-flow: row wrap;
  gap: 12px;
}
.button-prop {
  border-radius: 0.5rem;
  font-weight: 500;
  font-size: 1rem;
  padding: 0.6rem 1rem;
}
//button

//input
.input-prop {
  &>input {
    width: 100%;
    padding: 0.7rem 0.7rem 0.7rem 2rem;
  }
  &>label {left: 2.2rem;}
  &>i {left: 0.7rem;}
}
//input

// left-menu
.left-menu {
  transition-duration: 100ms;
  transition-property: width;
  width: 216px;
  position: fixed;
  z-index: 999;

  &__content-fog {
    background-color: rgba(0, 0, 0, .25);
    bottom: 0;
    left: -30px;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity .3s;
    visibility: hidden;
    z-index: 600;
    &.active {
      opacity: 1;
      visibility: visible;
    }
  }

  &__logo {
    padding: 40px 23px;
    flex-flow: column;
    flex-basis: 0;
    display: flex;
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .logo {
      width: 38px;
      margin-right: 8px;
      &__text {
        font-size: 20px;
        font-weight: 600;
      }
    }
  }

  .link {
    margin: 4px 0;
    padding: 0 8px;

    &__item {
      text-decoration: none;
      display: flex;
      align-items: center;
      color: var(--highlight-text-color);
      font-size: 16px;
      cursor: pointer;
      padding: 1rem 1rem 1rem 24px !important;
      width: 100% !important;
      border-radius: var(--border-radius) !important;

      &:hover, &.active {
        color: #ffffff;
        background-color: var(--cyan-600);
      }
    }

    &__icon {
      font-size: 22px;
      margin-right: 1rem !important;
    }
  }

  &__content {
    width: 100%;
    background-color: var(--cyan-100);
    height: 100vh !important;
  }

  &__avatar {
    flex-direction: column !important;
    display: flex !important;
    padding: 0 7px 14px;
  }

  .avatar {
    display: flex;
    flex-flow: row nowrap;
    column-gap: 16px;
    padding: 14px;
    align-items: center;
    .avatar__icon {
      background-color: #35C4DC;
      color: #ffffff;
    }
    &:hover {
      color: #ffffff;
      background-color: var(--cyan-600);
      border-radius: var(--border-radius) !important;
    }
  }

  .sub-menu {
    position: absolute;
    top: 0;
    left: 216px;
    width: 348px;
    height: 100vh;
    overflow: auto;
    flex-direction: column;
    background-color: #fff;
    border-radius: 0 20px 20px 0;
    visibility: visible;
    padding: 24px 0 0;
    opacity: 0;
    display: none;

    &.active {
      opacity: 1;
      display: flex;
    }

    &__menu {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    &__item {
      border-radius: 12px;
      margin: 2px 8px;
      padding: 0 12px;
    }

    &__link {
      text-decoration: none;
      border-radius: 12px;
      margin: 2px 0;
      padding: 10px 12px;
      font-size: 14px;
      color: #212121FF;
      width: 100%;
      display: inline-block;

      &:hover {
        background-color: #ededed
      }
    }

    &__user {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      margin: 2px 20px;
      padding: 10px 12px;
    }
  }

  &.active {
    display: block;
    width: 84px;
    top: 0;
    left: 0;
    z-index: 1;
    .logo-container .logo__text {
      display: none;
    }
    .link {
      display: flex;
      flex-flow: row nowrap;

      &__item {
        width: 46px !important;

        &:hover, &.active {
          color: var(--blue-500);
          background: none;
        }
      }

      &__text, &__chevron {
        display: none;
      }
    }

    .avatar {
      .avatar__text {
        display: none;
      }
    }
  }
}
.page-panel {
  &__header {
    font-size: 24px;
    margin-bottom: 2rem
  }
}

//buttons
.btn-back {
  display: flex;
  align-items: center;
  gap: 1rem;
  &>button {
    border-radius: 0.5rem;
    font-weight: 500;
    font-size: 1rem;
    padding: 0.7rem 1rem;
  }
}

.p-dropdown-label.p-inputtext {
  justify-content: center;
  align-items: center;
  display: flex
}